* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif !important;
  font-display: swap;
}

body {
  /* background: #000000; */
  color: #EEEEEE;
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
  /* display: none; */
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
  border: 2px solid transparent;
}

/*--------------------- 
          Scrollbar css 
  ----------------------------------*/

.h_web {
  height: -webkit-fill-available;
}

.w_web {
  width: -webkit-fill-available;
}

.background_gradient {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}

.header_background_gradient {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.nav-options div a:after {
  position: absolute;
  width: 0;
  left: 0;
  bottom: -1rem;
  transition: 0.3s ease-in;
}

.nav-options diz a:hover::after {
  width: 100%;
}

/* .background_click {
  z-index: 9998;
} */

.mobile_header_menu {
  z-index: 99999;
}

@media screen and (max-width: 1024px) {
  .nav-options {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 2px;
    width: 100%;
    height: 100vh;
    padding: 4rem 0rem;
    position: absolute;
    left: 0;
    top: -100vh;
    opacity: 0;
    transition: all 0.7s ease;
    flex-direction: column;
    background: #000;
  }

  .nav-options.active {
    top: 0;
    opacity: 1;
    z-index: 99999;
  }


  .nav-options div a {
    padding: 8px 20px;
    width: 100%;
    justify-content: space-between;
  }

  .nav-options div {
    width: 100%;
  }
}

.contact_banner {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/src/assets/image/contact-banner.webp');
  height: 50vh;
  width: 100%;
  object-fit: cover;
}

.blog_banner {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('https://asiapacificceramic.com/_next/image?url=%2Fassets%2Fimages%2Fblog%2Fbanner.jpg&w=1200&q=75');
  height: 50vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.partner_banner {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url('/src/assets/image/become_partner.webp');
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.solution_retail_banner {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/src/assets/image/solution/retails/banner.jpg');
  height: 60vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.solution_oil_banner {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/src/assets/image/solution/oilgas/banner.webp');
  height: 60vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.solution_construction_banner {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/src/assets/image/solution/construction/banner.webp');
  height: 60vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.solution_facial_banner {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/src/assets/image/solution/facial/banner.webp');
  height: 60vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.solution_smartcity_banner {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/src/assets/image/solution/smartcity/banner.webp');
  height: 60vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center; */
}

.solution_manufacturing_banner {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/src/assets/image/solution/manu/banner.jpg');
  height: 60vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width:640px) {
  .partner_banner {
    height: 50vh;
  }

  .solution_retail_banner,
  .solution_oil_banner,
  .solution_construction_banner,
  .solution_facial_banner,
  .solution_smartcity_banner,
  .solution_manufacturing_banner {
    height: 50vh;
  }
}

.partner_form_banner {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url('/src/assets/image/email_banner.webp');
  height: 500px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}


.contact_banner_home {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('/src/assets/image/contact-banner.webp');
  height: 200px;
  width: 100%;
  object-fit: cover;
}




.slick-prev,
.slick-next,
.slick-dots button:before,
.slick-active button::before {
  color: white !important;
}

.slick-prev,
.slick-next {
  width: 25px !important;
  height: 25px !important;
}

.blog_details h1 {
  font-size: 35px;
  font-weight: 600;
  margin: 10px 0;
  line-height: 43px;
}

.blog_details h2 {
  font-size: 30px;
  font-weight: 500;
  margin: 10px 0;
  line-height: 38px;
}

.blog_details h3 {
  font-size: 25px;
  font-weight: 400;
  margin: 10px 0;
  line-height: 33px;
}

.blog_details h4 {
  font-size: 22px;
  font-weight: 400;
  margin: 5px 0;
  line-height: 28px;
}


.blog_details p,
.blog_details ul li,
.blog_details ol li {
  font-size: 18px;
  font-weight: 300;
  color: #c1c1c1;
  margin-bottom: 8px;
  text-align: justify;
  line-height: 26px;
}

.blog_details ul {
  list-style: disc;
  padding-left: 40px;
}

.blog_details ol {
  list-style: decimal-leading-zero;
  padding-left: 40px;
}

.blog_details strong {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.blog_details a {
  text-decoration: underline !important;
  color: #fff;
  font-weight: 500 !important;
}

@media (max-width:600px) {
  .blog_details h1 {
    font-size: 22px;
    margin: 6px 0;
    line-height: 30px;
  }

  .blog_details h2 {
    font-size: 20px;
    margin: 6px 0;
    line-height: 28px;
  }

  .blog_details h3 {
    font-size: 18px;
    margin: 6px 0;
    line-height: 26px;
  }

  .blog_details h4 {
    font-size: 16px;
    margin: 5px 0;
    line-height: 24px;
  }

  .blog_details p,
  .blog_details ul li,
  .blog_details ol li {
    font-size: 13px;
    margin-bottom: 5px;
    line-height: 21px;
  }

  .blog_details ul {
    padding-left: 20px;
  }

  .blog_details ol {
    padding-left: 25px;
  }

  .blog_details strong {
    font-size: 15px;
    line-height: 23px;
  }
}

.center .slick-center .active_slide {
  opacity: 1;
  transform: scale(1.08);
}

.center .active_slide {
  opacity: 0.8;
  transition: all 300ms ease;
}

@media(max-width:600px) {
  .center .active_slide {
    height: 300px;
  }
}

.banner_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.client_feedback {
  object-fit: cover;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
@media (max-width:768px){
  .client_feedback {
    width: 40px;
    height: 40px;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader_parent{
  width: 99vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}